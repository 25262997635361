import React, {useState } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import './styles/top.scss';
import keyvisual from './img/keyvisual.jpeg';
import rogo from './img/rogo.png';
import arrow from './img/arrow.svg';
import Button from './components/button';
import img1 from './img/img-1.jpeg';
import img2 from './img/img-2.jpeg';
import img3 from './img/img-3.jpg';
import img4 from './img/img-4.jpg';
import img5 from './img/img-5.jpeg';
import img6 from './img/img-6.jpeg';
import img7 from './img/img-7.png';
import img9 from './img/img-9.png';
import instaicon from './img/instaicon.webp';



function Top() {
    const [ChangeGuideline, setChangeGuideline] = useState(false);
    const [Popup, setPopup] = useState(false);
    const [Popup2, setPopup2] = useState(false);
    const [Popup3, setPopup3] = useState(false);
    const [buttonText, setButtonText] = useState('カンファレンス');

    const ChangingGuideline = () => {
        if (!ChangeGuideline) {
          setChangeGuideline(true);
          setButtonText('応募・書類審査');
        } else {
          setChangeGuideline(false);
          setButtonText('Reception');
        }
      };

    const handleClick = () => {
        setPopup(prevState => !prevState);
    }
    const handleClick2 = () => {
        setPopup2(prevState => !prevState);
    }
    const handleClick3 = () => {
        setPopup3(prevState => !prevState);
    }
    
    
    return(
        <>
            <section id="main" className="Keyvisual">
                <div className="backgroundimg">
                    <div className="caption">
                        <h3 className="caption__title">Miss & Mrs. GAIA WORLD</h3>
                        <p className="caption__sub">-2024-</p>
                    </div>
                </div>
            </section>

            <section className="Catchcolumn">
                <h3 className="Catchcolumn__title">
                    Quadravitaは、<br/>
                    Miss & Mrs. GAIA WORLD JAPANを主催しています。
                </h3>
                <div>
                    <img src= {rogo} />
                </div>
                <p className="Catchcolumn__caption">
                    Miss & Mrs. GAIA WORLD は、美しさだけでなく知性、環境意識、社会貢献も重視す
                    る国際的な美のコンテストです。グランドガイアインターナショナルが主催し、ミスとミセスの2部門があります。世界各国から参加者が集まる本大会は、環境保護と平和
                    の推進、文化交流を目指しており、自分の強みや才能を発揮しながら、環境保護活動や慈善活動へ参加する参加者の姿勢が評価されます。
                </p>
            </section>

            <section className="News">
                <div className="News__box">
                    <div className="titlebox">
                        <h2 className="titlebox__title">NEWS</h2>
                    </div>
                    <div className="contents">
                        <div className="contents__box">
                            <p className="caption--board">2024.06.14</p>
                            <p>2024 Miss  & Mrs.  GAIA WORLD JAPAN 開催が決定いたしました。</p>
                            <div className="underline"></div>
                        </div>
                        <div className="contents__box">
                            <p className="caption--board">2024.06.28</p>
                            <p>2024 Miss  & Mrs.  GAIA WORLD  日本代表の選考を開始いたしました。</p>
                            <div className="underline"></div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="Entry">
                <div className="backgroundimg">
                    <h3 className="entrytitle">Miss & Mrs. GAIA WORLD JAPAN</h3>
                    <p className="entrycaption">-2024-</p>
                    <Button>ENTRY</Button>
                </div>
            </section>

            <section className="flow">
                <div className="flow__box">
                    <div className="title">
                        <div className="title__firstline"></div>
                        <div className="title__text">選考の流れ</div>
                        <div className="title__lastline"></div>
                    </div>
                    <div className="contentsbox">
                        <div className="contentsbox__titlebox width100">
                            <p>第一次選考</p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>
                                第一次選考 書類審査
                            </p>
                            <p>
                                自薦他薦は問いません。申し込みフォームより必要事項を入力しお申し込みください。
                            </p>
                        </div>
                    </div>

                    <div className="contentsbox">
                        <div className="contentsbox__titlebox width100">
                            <p>第二次選考</p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>
                                第二次選考 書類審査
                            </p>
                            <p>
                                自薦他薦は問いません。申し込みフォームより必要事項を入力しお申し込みください。
                            </p>
                        </div>
                    </div>

                    <div className="contentsbox">
                        <div className="contentsbox__titlebox width100">
                            <p>最終選考</p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>
                                合格者は世界大会出場費用のご入金
                            </p>
                            <p>
                                Mrs.  GAIA:7,500ドル<br />
                                Miss GAIA:4,000ドル
                            </p>
                            <button className="popup__button" onClick={handleClick}>費用には以下のものが含まれます</button>
                        </div>
                    </div>

                    <div className={`popup ${Popup ? 'open' : ''}`}>
                        <div className="filter"></div>
                        <div className="contents">
                            <h3>Miss & Mrs.  GAIAWORLD 日本代表の世界大会出場費には以下のものが含まれています。</h3>
                            <p>1. 日本で開催されるビューティーキャンプへの参加費</p>
                            <p>2. 星付きホテルへの宿泊</p>
                            <p>3. プロによるランウェイウォーキングレッスン</p>
                            <p>4. 滞在中の食事とホテル</p>
                            <p>5. ウェルカムディナー</p>
                            <p>6. タレントコンテストとグランドファイナル参加時のヘアメ イク</p>
                            <p>7. サッシュ</p>
                            <p>8. 受賞者へのクラウンとトロフィー</p>
                            <p>9. コンテスト雑誌へのフルカラーでの掲載</p>
                            <p>10. マレーシアの主要雑誌、新聞、主要オンラインメディア での報道(受賞者のみ)</p>
                            <p>11. グランドファイナルラグジュアリーバンケット</p>
                        </div>
                    </div>

                    <div className="contentsbox">
                        <div className="contentsbox__titlebox width400">
                            <p>トレーニング・プレリミナリー</p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>オンライントレーニング</p>
                            <p>
                                ・英語スピーチ原稿作成 <br />
                                ・英語スピーチトレーニング
                            </p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>ビューティーキャンプ</p>
                            <p>
                                ・英語スピーチトレーニング <br />
                                ・ランウェイウォーキングトレーニング<br />
                                会場:都内
                            </p>
                        </div>
                    </div>

                    <div className="contentsbox">
                        <div className="contentsbox__titlebox width500">
                            <p>Miss & Mrs.  GAIA WORlD 日本代表 授賞式</p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>
                                日程:2024 年 10 月 23 日(水)<br />
                                会場:青山 セントグレース大聖堂 レキシントン 5th クラブ邸
                            </p>
                        </div>
                    </div>

                    <div className="contentsbox">
                        <div className="contentsbox__titlebox width400">
                            <p>Miss & Mrs.  GAIA WORlD 世界大会</p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>
                                日程:2024 年 11 月 9 日(土)~12 日(火) <br />
                                会場:HGH Convention Centre<br />
                                (マレーシア・クアラルンプール)
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="gaiaaward" className="gaiaEntry">
                <div className="backgroundimg">
                    <h3 className="entrytitle">GAIA AWARD</h3>
                    <p className="entrycaption">-2024-</p>
                    <Button>ENTRY</Button>
                </div>
            </section>

            <section className="gaiaaward">
                <h3 className="title">
                    GAIA AWARD
                </h3>
                <p>
                    GAIA AWARD とは、世界及びアジア 17 ヵ国の中で影響力を与えた人々、企業や経営 者を表彰し、世界及びアジア各国で活躍できるリーダーを育成することを目的とした
                    マレーシア観光局認定のアワードです。
                    受賞すると世界アジア各国で活躍の場が広がります。
                </p>
            </section>

            <section className="flow--blue">
                <div className="flow__box">
                    <div className="title">
                        <div className="title__firstline"></div>
                        <div className="title__text">選考の流れ</div>
                        <div className="title__lastline"></div>
                    </div>
                    <div className="contentsbox">
                        <div className="contentsbox__titlebox width100 brown">
                            <p>第一次選考</p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>
                                第一次選考 書類審査
                            </p>
                            <p>
                                自薦他薦は問いません。申し込みフォームより必要事項を入力しお申し込みください。
                            </p>
                        </div>
                    </div>

                    <div className="contentsbox">
                        <div className="contentsbox__titlebox width100 brown">
                            <p>第二次選考</p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>
                                第二次選考 書類審査
                            </p>
                            <p>
                                自薦他薦は問いません。申し込みフォームより必要事項を入力しお申し込みください。
                            </p>
                        </div>
                    </div>

                    <div className="contentsbox">
                        <div className="contentsbox__titlebox width100 brown">
                            <p>最終選考</p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>
                                合格者は受賞費用のご入金
                            </p>
                            <p>
                                アワード受賞者（合格者）：7,500ドル
                            </p>
                            <button className="popup__button" onClick={handleClick2}>費用には以下のものが含まれます</button>
                        </div>
                    </div>

                    <div className={`popup ${Popup2 ? 'open2' : ''}`}>
                        <div className="filter"></div>
                        <div className="contents">
                            <h3>GAIA AWARD受賞費用には以下のものが含まれています。</h3>
                            <p>1. クラウンとサッシュの授与</p>
                            <p>2. コンテスト雑誌への１ページ掲載</p>
                            <p>3. コンテスト雑誌１冊プレゼント</p>
                            <p>4. マレーシアの有名ビジネス雑誌「創富天下」への１ページ掲載</p>
                            <p>5. グランドファイナル豪華ディナー</p>
                            <p>6. グランドファイナルレットカーペットセレモニーへ出席</p>
                            <p>7. プロモーションビデオの撮影</p>
                            <p>8. トロフィー授与</p>
                            <p>9. ミス・ミセスガイアワールドグランドファイナルでのアワード授賞式</p>
                            <p>10. グランドファイナルでのヘアメイク</p>
                            <p>11. プロによる写真・動画撮影</p>
                            <p>12.食事とホテル（1泊2日）</p>
                            <p>13.マレーシアの主要雑誌、新聞、主要オンラインメディアでの報道（受賞者のみ）</p>
                        </div>
                    </div>

                    <div className="contentsbox">
                        <div className="contentsbox__titlebox width400 brown">
                            <p>GAIA AWARD受賞者ご紹介</p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>GAIA AWARD受賞者ご紹介</p>
                            <p>
                                日程：2024年10月23日（水） <br />
                                会場：青山 セントグレース大聖堂 レキシントン5thクラブ邸
                            </p>
                        </div>
                    </div>

                    <div className="contentsbox">
                        <div className="contentsbox__titlebox width400 brown">
                            <p>GAIA AWARD授賞式</p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>GAIA AWARD授賞式</p>
                            <p>
                                日程：2024年11月11日（月）12日（火）<br />
                                会場:HGH Convention Centre<br />
                                (マレーシア・クアラルンプール)
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Quadravitaについて **/}
            <section id="quadraaward" className="quadraEntry">
                <div className="backgroundimg">
                    <h3 className="entrytitle">QUADRAVITA AWARD</h3>
                    <p className="entrycaption">-2024-</p>
                    <Button>ENTRY</Button>
                </div>
            </section>

            <section className="quadravision">
                <img src = {rogo} />

                <div className="mission">
                    <h3>Quadravitaのミッション</h3>
                    <p>輝く大人が溢れる日本！</p>
                    <p>〜子供が輝ける未来のためにまずは大人が輝こう！〜</p>
                </div>

                <div className="vision">
                    <h3>Quadravitaのビジョン</h3>
                    <p>
                        子供の希望に満ちた未来のため、子供に憧れられる大人を日本中に溢れさせよう！子供に強要するのではなく、まずは大人から！
                    </p>
                    <p>
                        子供がワクワクしながら成長していくために、まずは大人がワクワクしながら自分を成長させ、子どもに素敵な大人像を見せる！
                    </p>
                </div>

                <div className="value">
                    <h3 className="value__title">Quadravitaのバリュー</h3>
                    {/* <h3 className="value__cap">「知」「華」「優」「強」</h3> */}
                    <div className="value__container">
                        <div className="value__cap">

                            <h3 className="value__cap">「知」</h3>
                            <p>知性、教養、教育</p>

                        </div>

                        <div className="value__cap">

                            <h3 className="value__cap">「華」</h3>
                            <p>品格ある華やかさ</p>

                        </div>

                        <div className="value__cap">

                            <h3 className="value__cap">「優」</h3>
                            <p>優しくある</p>

                        </div>

                        <div className="value__cap">

                            <h3 className="value__cap">「強」</h3>
                            <p>強くある</p>

                        </div>
                            
                    </div>
                </div>
            </section>

            <section className="quadraaward">
                <div className="backgroundimg">
                    <div className="backgroundimg-filter"></div>
                    <div className="main">
                        <h3 className="titile">
                            Quadravita Award
                        </h3>
                        <p className="main__cap">知華優強の精神に則り、各分野で貢献した人・企業を称え表彰する</p>

                        <div className="awardbox">
                            <h3 className="title">
                                Intelligence Award （｢知｣部門）
                            </h3>
                            <p className="captitle">Innovator in Intelligence Award（最優秀 インテリジェンス イノベーター賞）</p>
                            <p>知識や情報の革新的な活用により社会に新しい視点をもたらし、先進的なアプローチで成果を上げた個人や企業に授与される賞</p>

                            <p className="captitle">Mentor of the Year Award（年間最優秀 メンター賞）</p>
                            <p>優れた指導力と啓発的なアプローチにより多くの人々の生活とキャリアに深い影響を 与え、彼らの成長と成功に寄与した個人や企業に授与される賞</p>

                            <p className="captitle">Educational Leadership Award（最優秀 教育リーダーシップ賞）</p>
                            <p>教育関連の分野でリーダーシップを発揮し、教育環境の向上に寄与した個人や企業に 授与される賞</p>
                            
                            <p className="captitle">Mentor of the Year Award（間最優秀 メンター賞）</p>
                            <p>優れた指導力と啓発的なアプローチにより多くの人々の生活とキャリアに深い影響を 与え、彼らの成長と成功に寄与した個人や企業に授与される賞</p>

                            <p className="captitle">Global Education Excellence Award（最優秀 グローバル教育賞）</p>
                            <p>日本の教育を世界水準に引き上げるために優れた貢献をした個人や企業に授与される 賞</p>
                        </div>

                        <div className="awardbox">
                            <h3 className="title">
                                Intelligence Award(「知」部門)
                            </h3>
                            <p className="captitle">Innovator in Intelligence Award(最優秀 インテリジェンス イノベーター賞)</p>
                            <p>知識や情報の革新的な活用により社会に新しい視点をもたらし、先進的なアプローチ で成果を上げた個人や企業に授与される賞</p>

                            <p className="captitle">Mentor of the Year Award(年間最優秀 メンター賞)</p>
                            <p>優れた指導力と啓発的なアプローチにより多くの人々の生活とキャリアに深い影響を 与え、彼らの成長と成功に寄与した個人や企業に授与される賞</p>

                            <p className="captitle">Educational Leadership Award(最優秀 教育リーダーシップ賞)</p>
                            <p>教育関連の分野でリーダーシップを発揮し、教育環境の向上に寄与した個人や企業に 授与される賞</p>
                            
                            <p className="captitle">Mentor of the Year Award(年間最優秀 メンター賞)</p>
                            <p>優れた指導力と啓発的なアプローチにより多くの人々の生活とキャリアに深い影響を 与え、彼らの成長と成功に寄与した個人や企業に授与される賞</p>

                            <p className="captitle">Global Education Excellence Award(最優秀 グローバル教育賞)</p>
                            <p>日本の教育を世界水準に引き上げるために優れた貢献をした個人や企業に授与される 賞</p>
                        </div>

                        <div className="awardbox">
                            <h3 className="title">
                                Brilliance Award(「華」部門)
                            </h3>
                            <p className="captitle">Crown of Charisma Award(最優秀 カリスマ賞)</p>
                            <p>芸術性や魅力、影響力により他者に強い感動を与え、社会や業界を牽引した個人や企 業に授与される賞</p>

                            <p className="captitle">Society Icon Award(最優秀 ソサエティ アイコン賞)</p>
                            <p>社交的なネットワーキングと文化的な交流をリードし、社交界の象徴として社会の発 展と調和を促進した個人や企業に授与される賞</p>

                            <p className="captitle">Cultural Ambassador Award(文化大使賞)</p>
                            <p>文化の交流と理解を促進し、国際的な文化交流に貢献した個人や企業に授与される賞</p>
                            
                            <p className="captitle">Beauty Innovator Award(最優秀 ビューティー イノベーター賞)</p>
                            <p>美を芸術の域に昇華させ、独自の視点や感性、技術によりその価値を高め社会に貢献 した個人や企業に授与される賞</p>
                        </div>

                        <div className="awardbox">
                            <h3 className="title">
                                Graciousness Award(「優」部門)
                            </h3>
                            <p className="captitle">Innovation Impact Award(最優秀 イノベーションインパクト賞)</p>
                            <p>優れたアイデアや行動で社会的課題の解決や持続可能性の促進に寄与し、より良い社 会の形成に大きな影響を与えた個人や企業に授与される賞</p>

                            <p className="captitle">Keeper of the Planet Award(最優秀 プラネットキーパー賞)</p>
                            <p>地球の資源と生態系の保護に尽力し、持続可能な未来を築くことに貢献した個人や企 業に授与される賞</p>

                            <p className="captitle">Heart of Gold Award(最優秀 ゴールドハート賞)</p>
                            <p>慈善活動や社会貢献を通じて、人々の生活やより良い未来に積極的に貢献した個人や 企業に授与される賞</p>
                            
                            <p className="captitle">Noblesse Oblige Award(最優秀 ノブレスオブリージュ賞)</p>
                            <p>高貴な精神と責任感を持ち、自らの成功を社会全体の利益に結びつけ、社会的な課題 の解決に尽力した個人や企業に授与される賞</p>
                        </div>

                        <div className="awardbox">
                            <h3 className="title">
                                Strength Award(「強」部門)
                            </h3>
                            <p className="captitle">Paladin of Life Award(最優秀 生命の騎士賞)</p>
                            <p>健康と生命の保護に多大な貢献を果たし、人々の生命力を強化するために卓越した活 動を展開した個人や企業に授与される賞</p>

                            <p className="captitle">Hero of Humanity Award(最優秀 人道の英雄賞)</p>
                            <p>人道的活動を通じて人々の生活を強化し、彼らに新たな力と希望をもたらした個人や 企業に授与される賞</p>

                            <p className="captitle">Empowerment Leadership Award(最優秀 エンパワメント リーダーシップ賞)</p>
                            <p>他者の能力を引き出し支援する卓越したリーダーシップを発揮し、個人や組織、コミュ ニティの成長と発展に大きく貢献した個人や企業に授与される賞</p>
                            
                            <p className="captitle">Social Resilience Innovator Award(最優秀 レジリエンス イノベーター賞)</p>
                            <p>革新的な解決策やアイデアを導入し、社会の強靭性を向上させた個人や企業に授与される賞</p>
                        </div>

                    </div>
                </div>
            </section>

            <section className="flow--blue">
                <div className="flow__box">
                    <div className="title">
                        <div className="title__firstline"></div>
                        <div className="title__text">選考の流れ</div>
                        <div className="title__lastline"></div>
                    </div>
                    <div className="contentsbox">
                        <div className="contentsbox__titlebox width100 brown">
                            <p>第一次選考</p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>
                                第一次選考 書類審査
                            </p>
                            <p>
                                自薦他薦は問いません。申し込みフォームより必要事項を入力しお申し込みください。
                            </p>
                        </div>
                    </div>

                    <div className="contentsbox">
                        <div className="contentsbox__titlebox width100 brown">
                            <p>第二次選考</p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>
                                第二次選考 審査
                            </p>
                            <p>
                                一般社団法人Quadravitaによる審査
                            </p>
                        </div>
                    </div>

                    <div className="contentsbox">
                        <div className="contentsbox__titlebox width100 brown">
                            <p>最終選考</p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>
                                合格者は受賞費用のご入金
                            </p>
                            <p>
                                アワード受賞者（合格者）：2,000ドル
                            </p>
                            <button className="popup__button" onClick={handleClick3}>費用には以下のものが含まれます</button>
                        </div>
                    </div>

                    <div className={`popup ${Popup3 ? 'open3' : ''}`}>
                        <div className="filter"></div>
                        <div className="contents">
                            <h3>Quadravita AWARD受賞費用には以下のものが含まれています。</h3>
                            <p>1.ウェブサイトへの掲載</p>
                            <p>2.アワード雑誌への掲載</p>
                            <p>3.表彰状</p>
                            <p>4.10月23日のパーティーS席1名</p>
                        </div>
                    </div>

                    <div className="contentsbox">
                        <div className="contentsbox__titlebox width400 brown">
                            <p>トレーニング・プレリミナリー</p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>オンライントレーニング</p>
                            <p>
                                ・英語スピーチ原稿作成 <br />
                                ・英語スピーチトレーニング
                            </p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>ビューティーキャンプ</p>
                            <p>
                                ・英語スピーチトレーニング <br />
                                ・ランウェイウォーキングトレーニング<br />
                                会場:都内
                            </p>
                        </div>
                    </div>

                    <div className="contentsbox">
                        <div className="contentsbox__titlebox width500 brown">
                            <p>GAIA AWARD授賞式</p>
                        </div>
                        <div className="contentsbox__textbox">
                            <p>
                                日程:2024 年 10 月 23 日(水)<br />
                                会場:青山 セントグレース大聖堂 レキシントン 5th クラブ邸
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="gallery">
                <div className="backgroundimg">
                    <Button>GALLERY</Button>
                </div>
            </section> */}

            <section className="company">
                <p>Miss & Mrs.  GAIA WORLD Japan</p>
                <p>Miss Asia Pacific International Japan</p>

                <p>運営</p>

                <p>一般社団法人Quadravita</p>
            </section>
        </>
    );
}

export default Top;
