import { FC , useState } from "react";
import "../styles/Navigation.scss";
import { HashLink } from 'react-router-hash-link';

type Props = {
  open: boolean;
  id: string;
};

export const Navigation: FC<Props> = ({ open, id }) => {
  return (
    <nav id={id} aria-hidden={!open} className="navigation">
      <div>
        <HashLink smooth to="/#main">MISS & MRS. GAIA WORLD</HashLink>
        <div>
          └<a href="https://forms.gle/t5t3bafgdoaYq743A">申し込み</a>
        </div>
      </div>
      <div>
        <HashLink smooth to="/#gaiaaward">GAIA AWARD</HashLink>
        <div>
          └<a href="https://forms.gle/t5t3bafgdoaYq743A">申し込み</a>
        </div>
      </div>
      <div>
        <HashLink smooth to="/#quadraaward">QUADRAVITA AWARD</HashLink>
      </div>
      <div>
          └<a href="https://forms.gle/t5t3bafgdoaYq743A">申し込み</a>
        </div>
    </nav>
  );
};