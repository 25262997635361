/* ボタン */
import React from 'react';
import '../styles/button.scss'; 
import { Link } from 'react-router-dom';

const Button = ({ to, children, color = 'Black', padding = '10px 35px', className = 'slidebtn' }) => {
  const style = {
    backgroundColor: color,
    color: 'White',
    padding: padding,
    border: 'none',
    textAlign: 'center',
    textDecoration: 'none',
  };

  return (
    <Link to={'https://forms.gle/t5t3bafgdoaYq743A'} style={style} className={className}>
      <p className="slidebtn__text">{children}</p>
    </Link>
  );
}

export default Button;