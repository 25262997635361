import { FC, useState } from "react";
import { ToggleButton } from './components/toggleButton.tsx';
import { Navigation } from './components/Navigation.tsx';
import "./styles/header.scss";
import rogo from './img/rogo.png';

const Header: FC = () => {
  const [open, setOpen] = useState(false);
  const toggleFunction = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <header className="header">
      <div className="wrapper">
        <div className="rogowithtext">
          <div className="rogobox">
            <img src={rogo} />
          </div>
          <p>Quadravita</p>
        </div>
      </div>
      <ToggleButton
        open={open}
        controls="navigation"
        label="メニューを開きます"
        onClick={toggleFunction}
      />
      <Navigation id="navigation" open={open} />
    </header>
  );
};
export default Header;