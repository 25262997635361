import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

/* import */
import Top from './Top.js';
import Header from './header.tsx';
import Footer from './footer.js';

function App() {
  return (
    <Router>
      
      <Routes>
        <Route index element={
          <main>
            <Header />
            <Top />
            <Footer />
          </main>
        } />
      </Routes>
    </Router>
  );
};

export default App;
